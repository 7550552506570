import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

export const SimpleFadeTransition = (props: any) => (
    <CSSTransition
        {...props}
        classNames="simplefadetransition"
        timeout={props.timeout ? props.timeout : { enter: 500, exit: 500 }}
    />
);

export const FadeTransition = (props: any) => (
    <CSSTransition
        {...props}
        classNames="fadetransition"
        timeout={props.timeout ? props.timeout : { enter: 500, exit: 500 }}
    />
);

export const SlowFadeTransition = (props: any) => (
    <CSSTransition
        {...props}
        classNames="slowfadetransition"
        appear={props.appear}
        timeout={props.timeout ? props.timeout : { enter: 1000, exit: 1000 }}
    />
);

export const PageTransition = (props: any) => (
    <CSSTransition
        {...props}
        classNames="pagestransition"
        timeout={1000}
    />
);

export const MessageTransition = (props: any) => (
    <CSSTransition
        {...props}
        classNames="messagetransition"
        timeout={props.timeout ? props.timeout : { enter: 700, exit: 700 }}
    />
);
