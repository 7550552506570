/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { companionPage, communityPage } from '@inwink/react-utils/navigation/companionpage';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { HelmetProps, Helmet as HelmetDef } from 'react-helmet';
import type { States } from '../../services/services';
import { cleanupUrl, getMicrodata, getShareData, removeHTML } from '../../data/metadata';
import { actions as pageActions } from '../../services/pageservice';
import { getUrlSlug } from 'helpers';

const Helmet : React.ComponentClass<HelmetProps & { children: React.ReactNode}> = HelmetDef as any; 

export interface IDynamicPageMetaDataProps {
    datacontext: any;
    page: States.ICurrentPageState;
    event: States.IEventState;
    community: States.ICommunityState;
    rootwebsite: States.IRootWebsiteState;
    currentLanguageCode: string;
    webmasterConfiguration: VisualTheme.IWebmasterConfig;
    pages: States.IPagesState;
    pageActions: typeof pageActions;
    customcontext?: any;
    match?: States.ILocationMatch;
    i18nHelper?: Entities.i18nHelper;
    ssr: States.ISSRState;
}

// eslint-disable-next-line max-len
declare type IPageUrlProvider = (rooturl: string, application: string, contenttype: string, tinyurl: string, lng?: string, urlid?: string, slug?: string) => string;

@withI18nHelper()
export class DynamicPageMetaData extends React.PureComponent<IDynamicPageMetaDataProps, any> {
    unmounted: boolean;

    constructor(props: IDynamicPageMetaDataProps) {
        super(props);
        const page = props.page;
        const entity = (page.context && page.context.entity) ? page.context.entity : {};
        const context = { ...props.datacontext, ...entity };
        this.state = {
            context: context
        };
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevprops: IDynamicPageMetaDataProps) {
        if (prevprops.datacontext !== this.props.datacontext || prevprops.page !== this.props.page) {
            const page = this.props.page;
            const entity = (page.context && page.context.entity) ? page.context.entity : {};
            const context = { ...this.props.datacontext, ...entity };
            this.setState({
                context: context
            });
        }
    }

    renderMetas(meta, index, from, i18n: Entities.i18nHelper) {
        let metaTagProps = {
            key: `custom-meta-${from + "-" + index}`
        };
        if (meta && meta.metadatas && meta.metadatas.length) {
            meta.metadatas.map((m) => {
                metaTagProps = Object.assign({}, metaTagProps, { [m.name]: i18n.translateBag(m.content) });
                return null;
            });
        }

        return React.createElement("meta", metaTagProps);
    }

    applyMetas(meta, metaTags) {
        const currentMetaTags = Object.assign([], metaTags);
        if (meta) {
            if (meta.length) {
                currentMetaTags.push(...meta);
            } else {
                currentMetaTags.push(meta);
            }
        }
        return currentMetaTags;
    }

    getPageTitle = () => {
        const page = this.props.page;
        const template = page.template?.config;
        const i18n = this.props.i18nHelper;
        const metadata: VisualTheme.IContentTemplateConfigMetadata = Object.assign({}, template?.metadata);

        const staticTitle = getPageStaticTitle(page, i18n);

        if (staticTitle) return staticTitle;

        if (metadata.dynamictitle) {
            const title = metadata.dynamictitle.map((k) => {
                const text = getPropertyValue(this.state.context, k.name);
                if (typeof text === "object") {
                    return i18n.translateBag(text);
                }

                return text;
            }).filter((s) => s !== null && s !== undefined).join(" ");

            if (title) {
                // On retire les balises html du titre. #45985
                const regex = /(<([^>]+)>)/ig;
                const _title = title.replace(regex, "");
                return _title;
            }
        }

        return getDefaultSiteName(this.props);
    };

    handleCanonicalUrls(
        rooturl: string,
        lngConfig: Entities.IGlobalLanguageConfiguration,
        entity: any,
        templ: Entities.IContentTemplate,
        pageProvider: IPageUrlProvider,
        metaTags: any[]) {
            
        if (!rooturl.endsWith("/")) {
            rooturl += "/";
        }
        let entityTitle = entity?.title || entity?.name;
        if (!entityTitle && entity?.firstname && entity?.lastname) {
            entityTitle = `${entity?.firstname} ${entity?.lastname}`;
        }
        
        const languages = lngConfig?.supportedLanguages;
        const defaultLanguage = lngConfig?.defaultLanguage;
        if (languages && languages.length > 1) {
            if (defaultLanguage) {
                const defaultLngSlug = getUrlSlug(entityTitle, defaultLanguage);
                metaTags.push(React.createElement("link" as any, {
                    key: "lngdefault",
                    rel: "alternate",
                    hreflang: "x-default",
                    href: cleanupUrl(pageProvider(rooturl, templ.application, templ.contentType,
                        templ.tinyUrl, null, entity && entity.id, defaultLngSlug))
                }));
            }

            languages.forEach((l) => {
                let lngEntityTitle = entity?.title || entity?.name;
                if (!lngEntityTitle && entity?.firstname && entity?.lastname) {
                    lngEntityTitle = `${entity?.firstname} ${entity?.lastname}`;
                }
                const lngSlug = getUrlSlug(lngEntityTitle, l);
                metaTags.push(React.createElement("link" as any, {
                    key: "lng" + l,
                    rel: "alternate",
                    hreflang: l,
                    href: cleanupUrl(pageProvider(rooturl, templ.application, templ.contentType,
                        templ.tinyUrl, l, entity && entity.id, lngSlug))
                }));
            });

            const slug = getUrlSlug(entityTitle, this.props.currentLanguageCode);
            metaTags.push(React.createElement("link" as any, {
                key: "canonical",
                rel: "canonical",
                href: cleanupUrl(pageProvider(rooturl, templ.application, templ.contentType,
                    templ.tinyUrl, this.props.currentLanguageCode, entity && entity.id, slug))
            }));
        } else {
            const slug = getUrlSlug(entityTitle, this.props.currentLanguageCode);
            metaTags.push(React.createElement("link" as any, {
                key: "canonical",
                rel: "canonical",
                href: cleanupUrl(pageProvider(rooturl, templ.application, templ.contentType,
                    templ.tinyUrl, null, entity && entity.id, slug))
            }));
        }
    }

    render() {
        const page = this.props.page;
        if (this.props.ssr?.firstPass) {
            return null;
        }
        // logger.verbose("render page metadata");
        const i18n = this.props.i18nHelper;
        const title = this.getPageTitle();
        let metaTags: any[] = [];
        const templ = page.template;
        const template = page.template?.config;
        const webmaster = this.props.webmasterConfiguration;
        const metadata: VisualTheme.IContentTemplateConfigMetadata = Object.assign({}, template?.metadata);
        const entity = (page.context && page.context.entity) ? page.context.entity : {};

        if (title) {
            metaTags.push(<title key="title">{title}</title>);
        }
        if (metadata && metadata.description) {
            const desc = i18n.translateBag(metadata.description);
            if (desc) {
                metaTags.push(<meta key="description" name="description" content={desc} />);
            }
        }
        if (metadata && metadata.keywords) {
            const keywords = i18n.translateBag(metadata.keywords);
            if (keywords) {
                metaTags.push(<meta key="keywords" name="keywords" content={keywords} />);
            }
        }
        if (metadata && metadata.meta && metadata.meta.length) {
            // Metas provenant de la page courante.
            metadata.meta.map((meta, idx) => {
                const metaTag = this.renderMetas(meta, idx, "page", i18n);
                metaTags = this.applyMetas(metaTag, metaTags);
                return null;
            });
        }
        if (webmaster && webmaster.head && webmaster.head.metas && webmaster.head.metas.length) {
            // Metas provenant de la configuration webmaster.
            webmaster.head.metas.map((meta, index) => {
                const metaTag = this.renderMetas(meta, index, "webmaster", i18n);
                metaTags = this.applyMetas(metaTag, metaTags);
                return null;
            });
        }

        if (templ && !InWinkPreview) {
            let rooturl: string = null;
            let lngConfig: Entities.IGlobalLanguageConfiguration = null;
            let pageProvider: IPageUrlProvider;

            if (this.props.event?.detail) {
                const eventdetail = this.props.event.detail;
                rooturl = eventdetail.computedUrl;
                lngConfig = eventdetail.configuration?.global;
                pageProvider = companionPage;
            } else if (this.props.community?.detail) {
                const communityDetail = this.props.community.detail;
                rooturl = communityDetail.computedUrl;
                lngConfig = communityDetail.configuration?.global;
                pageProvider = communityPage;
            } else if (this.props.rootwebsite?.detail) {
                const rootwebsiteDetail = this.props.rootwebsite.detail;
                rooturl = rootwebsiteDetail.computedUrl;
                lngConfig = rootwebsiteDetail.configuration?.global;
                pageProvider = companionPage;
            }

            if (pageProvider && rooturl && lngConfig) {                            
                this.handleCanonicalUrls(
                    rooturl, lngConfig, entity, templ, pageProvider, metaTags);
            }
        }
        
        if (webmaster && webmaster.metadata && webmaster.metadata.meta) {
            const currentlngmeta = webmaster.metadata.meta[i18n.i18n.currentLanguageCode];
            if (currentlngmeta) {
                currentlngmeta.forEach((m) => {
                    metaTags.push(<meta key={m.name} name={m.name} content={m.content} />);
                });
            }
        }

        if (metadata.meta) {
            const currentlngmeta2 = metadata.meta[i18n.i18n.currentLanguageCode];
            if (currentlngmeta2) {
                currentlngmeta2.forEach((m) => {
                    metaTags.push(<meta key={m.name} name={m.name} content={m.content} />);
                });
            }
        }

        if (metadata.dynamicmeta) {
            metadata.dynamicmeta.forEach((meta) => {
                if (meta.content && Array.isArray(meta.content)) {
                    const content = meta.content.map((k) => {
                        const res = getPropertyValue(this.state.context, k.name);
                        if (res && typeof res === "string") return removeHTML(res);
                        if (res && res.forEach) {
                            return res.join(", ");
                        }
                        if (res && res[this.props.currentLanguageCode]) {
                            return removeHTML(res[this.props.currentLanguageCode]);
                        }
                        return null;
                    }).filter((s) => s !== null && s !== undefined).join(", ");

                    metaTags.push(<meta key={meta.name} name={meta.name} content={content} />);
                }
            });
        }

        const currentPage = this.props.pages.currentPage;
        if (typeof window !== "undefined"
            && (!currentPage || currentPage.title !== title)
            && !this.props.customcontext?.fromModal) {
            setTimeout(() => {
                if (!this.unmounted) {
                    this.props.pageActions.setCurrentPageTitle(page.id, title);
                }
            }, 50);
        }

        if (template?.metadata) {
            const share = getShareData(
                i18n, template.metadata, this.props.webmasterConfiguration, entity, this.props.datacontext, this.props.ssr);

            if (share) {
                if (share.title) {
                    metaTags.push(
                        <meta key="og:title" property="og:title" content={share.title} />,
                        <meta key="twitter:title" property="twitter:title" content={share.title} />
                    );
                }
                if (share.description) {
                    metaTags.push(
                        <meta key="og:description" property="og:description" content={share.description} />,
                        <meta key="twitter:description" property="twitter:description" content={share.description} />
                    );
                }
                if (share.picture) {
                    metaTags.push(
                        <meta key="og:image" property="og:image" content={share.picture} />,
                        <meta key="twitter:image" property="twitter:image" content={share.picture} />,
                        <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
                    );
                }
                if (share.url) {
                    metaTags.push(
                        <meta key="og:url" property="og:url" content={share.url} />,
                        <meta key="twitter:url" property="twitter:url" content={share.url} />
                    );
                }
                if (share.type) {
                    metaTags.push(<meta key="og:type" property="og:type" content={share.type} />);
                }
            }
        }

        return <Helmet>{metaTags}</Helmet>;
    }
}

function getDefaultSiteName(
    arg: {event?: States.IEventState, community?: States.ICommunityState, rootwebsite?: States.IRootWebsiteState}
) {
    if (arg.event?.detail?.title) {
        return arg.event.detail.title;
    }
    if (arg.community?.detail?.name) {
        return arg.community.detail.name;
    }
    if (arg.rootwebsite?.detail?.name) {
        return arg.rootwebsite.detail.name;
    }

    return "inwink";
}

export function getPageStaticTitle(page: States.ICurrentPageState, i18n: Entities.i18nHelper) {
    const template = page.template?.config;
    const metadata: VisualTheme.IContentTemplateConfigMetadata = Object.assign({}, template?.metadata);
    let title;

    if (metadata && metadata.title) {
        title = i18n.translateBag(metadata.title) || "inwink";
        if (title) {
            // On retire les balises html du titre. #45985
            const regex = /(<([^>]+)>)/ig;
            const _title = title.replace(regex, "");
            return _title;
        }
    }

    return null;

}

@withI18nHelper()
export class DynamicPageMicrodata extends React.PureComponent<IDynamicPageMetaDataProps, any> {
    render() {
        const page = this.props.page;
        if (this.props.ssr?.firstPass || !page.template) {
            return null;
        }
        // logger.verbose("render page metadata");
        const template = page.template.config;
        const entity = (page.context && page.context.entity) ? page.context.entity : {};
        const microdata = getMicrodata(
            this.props.i18nHelper,
            this.props.event?.detail,
            template.metadata,
            this.props.webmasterConfiguration,
            typeof window !== "undefined" ? window.location.href : this.props.ssr?.currentPath,
            entity
        );

        if (microdata) {
            return <script
                key="microdata"
                type="application/ld+json"
                suppressHydrationWarning={true}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: microdata
                }}
            />;
        }

        return null;
    }
}
